/* You can add global styles to this file, and also import other style files */
@import './common.scss';
@import './assets/fonts/style.css';
@import './assets/fonts/Open_Sans/styles.css';
@import './assets/fonts/Helvetica/styles.css';

html,
body {
  height: 100%;
  font-family: "Gotham Pro";
}

body {
  margin: 0;
  background-color: #ffffff;
}

div:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea:focus,
input:focus {
  outline: none;
}

body ::-webkit-scrollbar {
  opacity: 0.4;
  width: 6px;
  height: 6px;
  opacity: 0.4;
  border-radius: 90px;
  background-color: #f6f8fb;
}

body ::-webkit-scrollbar-track {
  background: #f6f8fb;
}

body ::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  background-color: #bababa;
}

body ::-webkit-scrollbar-thumb:hover {
  background: #8b8b8b;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none !important;
}

.mat-calendar-body-cell-content {
  border-radius: 3px !important;
}

.mat-icon-button:hover {
  border-radius: 3px !important;
}

.mat-button-focus-overlay {
  background: transparent !important;
}

.mat-dialog-container {
  padding: 0px !important;
  background-color: #ffffff;
}

input::placeholder {
  font-size: 12px;
}

.main-container {
  min-height: calc(100vh - 241px);
}

textarea {
  border: none;
  box-sizing: border-box;
  transition: border 0.25s;
  padding: 7px 20px;
  border-radius: 3px;
  border: solid 1px #acb6c3;
  background-color: #ffffff;
  width: -webkit-fill-available;
  max-width: 100%;
  caret-color: #2f3441cc;
}

textarea::-webkit-input-placeholder {
  font-weight: 500;
  color: #cad6e3;
}

textarea:-moz-placeholder {
  font-weight: 500;
  color: #cad6e3;
}

textarea::-moz-placeholder {
  font-weight: 500;
  color: #cad6e3;
}

textarea:-ms-input-placeholder {
  font-weight: 500;
  color: #cad6e3;
}

textarea::placeholder {
  font-weight: 500;
  color: #cad6e3;
}

@media only screen and (min-width: 1445px) {
  .small-full-width {
    display: block !important;
  }

  .half-full-width {
    display: block !important;
  }

  .medium-full-width {
    display: block !important;
  }

  .full-width {
    display: none !important;
  }

  .small-medium-width {
    display: none !important;
  }

  .half-medium-width {
    display: none !important;
  }

  .medium-width {
    display: none !important;
  }

  .small-half-width {
    display: none !important;
  }

  .half-width {
    display: none !important;
  }

  .small-width {
    display: none !important;
  }

  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1444px) {
  .small-full-width {
    display: block !important;
  }

  .half-full-width {
    display: block !important;
  }

  .medium-full-width {
    display: block !important;
  }

  .full-width {
    display: none !important;
  }

  .small-medium-width {
    display: block !important;
  }

  .half-medium-width {
    display: block !important;
  }

  .medium-width {
    display: block !important;
  }

  .small-half-width {
    display: none !important;
  }

  .half-width {
    display: none !important;
  }

  .small-width {
    display: none !important;
  }

  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .small-full-width {
    display: block !important;
  }

  .half-full-width {
    display: block !important;
  }

  .medium-full-width {
    display: none !important;
  }

  .full-width {
    display: none !important;
  }

  .small-medium-width {
    display: block !important;
  }

  .half-medium-width {
    display: block !important;
  }

  .medium-width {
    display: none !important;
  }

  .small-half-width {
    display: block !important;
  }

  .half-width {
    display: block !important;
  }

  .small-width {
    display: none !important;
  }

  body {
    overflow-x: auto;
  }
}

@media only screen and (max-width: 768px) {
  .small-full-width {
    display: block !important;
  }

  .half-full-width {
    display: none !important;
  }

  .medium-full-width {
    display: none !important;
  }

  .full-width {
    display: none !important;
  }

  .small-medium-width {
    display: block !important;
  }

  .half-medium-width {
    display: none !important;
  }

  .medium-width {
    display: none !important;
  }

  .small-half-width {
    display: block !important;
  }

  .half-width {
    display: none !important;
  }

  .small-width {
    display: block !important;
  }

  body {
    overflow-x: auto;
  }
}

.notify-error {
  background-color: #ef0000;
}

.notify-info {
  background-color: #1f469d;
}

.notify-success {
  background-color: #4cc864;
}

.notify-warning {
  background-color: #fed533;
}

.notify-wrapper {
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.26);
  padding: 16px;
  margin: 20px 0;
  color: white;
  font-size: 14px;
}

.separator-horizon {
  width: 100%;
  height: 8px;
  margin: 23.5px 0.5px 19px 1px;
  border-radius: 50px;
  background-color: #f5f6f8;
}
ol, ul {
  list-style: none;
}
li:focus {
   outline: 0;
   border: none;
   -moz-outline-style: none;
}
h1.main-title {
  margin-bottom: 40px;
  padding-top: 50px;
  width: 80%;
  margin: 0px auto 40px auto;
}
.overflow-dots {
  white-space: nowrap;
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price-up {
  color: #238e48;
}
.price-down {
  color: #bb4e2b;
}

.title-small {
  font-size: 18px;
  color: #2f3441;
  margin: 0px;
  padding: 25px 0px 15px 0px;
  text-align: center;
}

//--------------------------accordion----------------//
.accordion-title {
  text-align: right;
  font-size: 1em;
  opacity: 0.75;
  cursor: pointer;
  margin: 0;
}
.accordion-arrow-wrapper {
  display: inline-block;
  width: 24px;
  margin-right: 16px;
  height: 45px;
  position: relative;
}
img.accordion-arrow {
  position: absolute;
  top: 10px;
  transform: rotate(-90deg);
  transition: transform 0.3s;
}
img.accordion-arrow.open {
  transform: rotate(90deg);
}
.accordion-wrapper {
  height: 0px;
  overflow: hidden;
  transition: height 0.3s;
}
.accordion-wrapper.open {
  height: unset;
}
.accordion-wrapper-block {
  display: none;
}
.accordion-wrapper-block.open {
  display: block;
}
//--------------------------accordion-END----------------//
.text-info {
  font-family: 'Gotham Pro Light';
  font-size: 1em;
  line-height: 2em;
}
//--------------------------Expand----------------//
.expand {
  padding: 0px 20px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);
  background-color: #fff;
  animation-delay: 0.2s;
  transition: 0.5s;
  margin-top: 16px;
}
.expand.open .expand-header {
  border-bottom: 1px solid #e8ecf3;
}
.expand-header {
  display: flex;
  height: 120px;
}
.expand-header-image-wrapper img {
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.expand-content {
  display: none;
}
.expand-content.open {
  display: block;
}
.expand-header-arrow-wrapper {
  display: inline-block;
  width: 40px;
  position: relative;
  transition: all 0.3s;
}
.expand-header-title {
  font-family: 'Gotham Pro Bold';
  color: #152844;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  width: max-content;
}
.expand-header-title-wrapper {
  display: inline-block;
  position: relative;
}
.expand-header-arrow-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
}
.expand-header-arrow-wrapper.open {
  transform: rotate(180deg);
}
.expand-filter-container {
  margin: 20px 0px;
}
.table-wrapper {
  overflow: auto;
}
.empty-list {
  height: 200px;
  position: relative;
}
.empty-list p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  width: 100%;
  opacity: 0.75;
  text-align: center;
}
.expand-title {
  font-family: "Gotham Pro Medium";
  font-size: 1.5em;
  color: #152844;
  text-align: center;
  margin: 30px 0px;
}
.expand-header-image-wrapper {
  width: 120px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .mobile-hide-block {
    display: none;
  }
  .expand-header-image-wrapper {
    display: none;
  }
  .expand-header-title-wrapper {
    width: calc(100% - 40px);
  }
  .expand-header-title {
    margin: 0px 10px;
    font-size: 1.2em;
  }
}
@media only screen and (min-width: 769px) {
  .main-container {
    border-bottom: 1px solid #dae2ed;
  }
  .expand-header-image-wrapper {
    display: inline-block;
  }
  .expand-header-title-wrapper {
    width: calc(100% - 160px);
  }
  .expand-header-title {
    margin: 0px 10px 0px 30px;
    font-size: 1.5em;
  }
}
//--------------------------Expand-end----------------//
//--------------------------Table------------------------//
.table-wrapper {
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.table__header {
  font-family: 'Gotham Pro';
  text-align: left;
}
.table-wrapper th {
  font-size: 13px;
  line-height: 1.54;
  padding: 15px;
  cursor: pointer;
  border-bottom: solid 1px #e8ecf3;
}
.table-header-arrow_down {
  margin-left: 5px;
  opacity: 0.1;
  transition: opacity 0.25s, transform 0.25s;
}
.table-wrapper th:hover .table-header-arrow_down, .table-wrapper th.active .table-header-arrow_down {
  opacity: 1;
}
th.active.non-desc img {
  transform: rotate(180deg);
}
tbody {
  padding: 16px;
}
.table-wrapper tbody.pointer tr {
  cursor: pointer;
}
.table-wrapper tr {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
  background-color: #fff;
  padding-left: 24px;
}
.table-wrapper td {
  padding: 40px 16px;
}
td.td-phone {
  min-width: 150px;
}
//--------------------------Table-end-------------------//
img:focus {
  outline: none;
}
@media only screen and (max-width: 581px) {
  .dialog-btn-wrapper-mobile {
    display: block;
    margin: 0 auto;
  }
  .dialog-btn-wrapper-mobile .button {
    width: 100% !important;
    margin: 5px auto;
  }
  .dialog-btn-wrapper-mobile .button.unfilled {
    width: 100%;
    padding: 0;
  }
  .dialog-btn-wrapper-full {
    display: none;
  }
}
@media only screen and (min-width: 580px) {
  .dialog-btn-wrapper-mobile {
    display: none;
  }
  .dialog-btn-wrapper-full {
    display: block;
  }
}

//--------------------------Modal-----------------------//
.modal-background {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(1px);
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation-delay: 0.2s;
  transition: 0.25s;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
}
.modal-background_visible {
  opacity: 1;
}
.modal {
  max-width: 620px;
  min-width: 350px;
  width: 90%;
  border-radius: 6px;
  box-shadow: 0 12px 42px 12px rgba(0,0,0,0.18);
  background-color: #fff;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  flex-direction: row;
}
.modal-title {
  font-size: 1.5em;
}
.modal-subtitle {
  font-size: 0.8em;
  opacity: 0.75;
  padding: 0px 24px 10px 24px;
}
.modal-button-close {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.modal-wrapper {
  padding: 10px 24px 24px 24px;
}
//--------------------------Modal-end-------------------//

//--------------------------Dialog-----------------------//
.dialog-btn-one-wrapper {
  padding: 20px 0px;
  text-align: center;
}
header.dialog-header {
  position: relative;
  padding: 20px;
}
header.dialog-header img {
  position: absolute;
  top: 2px;
  right: -18px;
  cursor: pointer;
  width: 1.6em;
}
.dialog-title {
  font-size: 1.5em;
  font-family: "Gotham Pro Bold";
  margin: 0px;
  text-align: center;
}
.dialog-wrapper {
  padding: 6px 24px 24px 24px;
  min-width: 200px;
  max-width: 800px;
  max-height: calc(100vh - 100px);
  min-height: 165px;
}

.dialog-wrapper.scrollbar {
  overflow-y: auto;
}
//--------------------------Dialog-end-------------------//

.reset-all-wrapper-full {
  height: 20px;
}
.reset-all-wrapper p {
  margin: 0;
}
.reset-all-wrapper {
  height: 20px;
}
.main-title {
  font-family: 'Open Sans';
  font-weight: bold;
  letter-spacing: 0.71px;
  margin: 0px 0px 40px 0px;
  font-size: 2em;
}
.auth-subtitle {
  font-family: 'Gotham Pro Light';
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 0px 0px 20px 0px;
}
.main-subtitle {
  font-family: "Gotham Pro Light";
  font-size: 1.5em;
  line-height: 2.25;
  margin: 0px 0px 20px 0px;
}

@media only screen and (max-width: 768px) {
  .half-left-user-setting,
  .half-right-user-setting {
    display: block;
    width: 100%;
    margin-bottom: 25px;
  }
  .temp-settings {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .half-left-user-setting,
  .half-right-user-setting {
    display: inline-block;
    width: 48%;
  }

  .half-right-user-setting {
    margin-left: 2%;
  }
  .operation-item-one-third,
  .operation-item-two-third,
  .operation-half-item-left-one,
  .operation-half-item-left-two,
  .operation-half-item-right-one,
  .half-left-user-setting {
    margin-right: 2%;
  }

  .operation-item-one-third,
  .operation-item-two-third,
  .operation-item-three-third {
    display: inline-block;
    width: 32%;
  }
}
.operation-filter-item {
  margin: 0px 10px;
}

.operation-half-item-left-one,
.operation-half-item-left-two,
.operation-half-item-right-one,
.operation-half-item-right-two {
  display: inline-block;
  width: 23.5%;
}

.operation-half-item-right,
.operation-half-item-left {
  display: inline-block;
  width: 48%;
}
.operation-half-item-left {
  margin-right: 2%;
}
.operation-half-item-right {
  margin-left: 2%;
}
.operation-array-button-wrapper {
  text-align: right;
}
@media only screen and (min-width: 769px) {
  .menu-block-wrapper {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .menu-block-wrapper {
    display: block;
    height: 80px;
  }
  .operation-item-one-third,
  .operation-item-two-third,
  .operation-item-three-third {
    width: 100%;
  }
  .operation-half-item-left-one,
  .operation-half-item-left-two,
  .operation-half-item-right-one,
  .operation-half-item-right-two {
    display: inline-block;
    width: 48%;
  }
  .operation-half-item-left-one,
  .operation-half-item-right-one {
    margin-right: 2%;
  }
  .operation-half-item-left-two,
  .operation-half-item-right-two{
    margin-left: 2%;
  }
}

@media only screen and (max-width: 601px) {
  .operation-half-item-left,
  .operation-half-item-right,
  .document-series-wrapper,
  .document-number-wrapper{
    display: block;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.document-number-wrapper.full{
  display: block;
  width: 100%;
  margin-left: 0%;
}

@media only screen and (min-width: 601px) {
  .operation-half-item-left,
  .operation-half-item-right,
  .document-series-wrapper,
  .document-number-wrapper{
    display: inline-block;
  }
  .operation-half-item-left,
  .operation-half-item-right {
    width: 48%;
  }
  .operation-half-item-left {
    margin-right: 2%;
  }
  .operation-half-item-right {
    margin-left: 2%;
  }
  .document-number-wrapper {
    width: 58%;
    margin-left: 2%;
  }
  .document-series-wrapper {
    width: 38%;
    margin-right: 2%;
  }
}
.operation-filter-wrapper .error-wrapper {
  min-height: 0px;
}
.left-menu-footer-logo {
  width: 120px;
}

//-----------------Toast------------------//
.toast-title {
  font-size: 1.4em;
  font-weight: 100 !important;
  margin-bottom: 10px;
}
.toast-title {
  font-size: 1.4em;
  font-weight: 100;
  margin-bottom: 0.5em;
}
@media only screen and (max-width: 600px) {
  .toast-title {
    text-align: center;
  }
  .toast-top-right {
    top: 12px;
    right: 12px;
    left: 12px;
  }
}
.toast-message {
  line-height: 1.5em;
}
.notify-wrapper {
  max-width: 600px;
}
//-----------------Toast-end--------------//
.popup-loader .mat-progress-spinner circle,
.popup-loader .mat-spinner circle {
  stroke: white;
}
.rate-value {
  font-style: italic;
  font-family: 'Gotham Pro' !important;
}

.modal {
  overflow-y: auto;
}
.full-hint .input-hint-bottom {
  white-space: unset !important;
  height: unset !important;
}
.entity-setting-hint {
  font-size: 14px;
  opacity: 0.75;
  margin: 20px 0px;
}

//------------------Loader center---------------//
.load-wrapper {
  min-width: 200px;
  max-width: 800px;
  min-height: 100px;
  position: relative;
}
.load-info {
  text-align: center;
  font-family: 'Gotham Pro Light';
  font-size: 1em;
  margin: 0px auto 43px auto;
}
.load-wrapper img {
  width: 50px;
}
.load-wrapper img,
.load-wrapper mat-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.load-final-info {
  width: 90%;
  margin: 0 auto;
  min-width: 150px;
  padding: 10px 0px 10px 0px;
}
.client-edit-btn {
  cursor: pointer;
  color: #b10762;
  text-align: center;
  padding: 20px 10px 0px 10px;
  font-weight: bold;
}
.word-break-all {
  word-break: break-all !important;
}


.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}